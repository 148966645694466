<script setup lang="ts">
import { computed, onBeforeUnmount, ref, watch } from 'vue'
import { onBeforeRouteUpdate, useRoute, useRouter } from 'vue-router'
import vuexStore from '@/vuex'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'
import LeaderLine from 'leader-line-new'
import { getAlgorithmShortcode, getRandomGradientNumber, getStatusCodeMeaning } from '@/utils/helpers/controls'
import { addLeaderLine, removeLeaderLine } from '@/views/AIControls/App/leaderLines'
import PageHeader from '@/components/PageHeader.vue'
import ControlsAlgorithmCard from '@/views/AIControls/ControlsAlgorithmCard.vue'
import ControlsAppSelector from '@/views/AIControls/ControlsAppSelector.vue'
import PinCardColumn from '@/views/AIControls/App/PinCardColumn.vue'
import { useAppStore } from '@/stores/app'
import { useAIControlsAppStore } from '@/stores/views/AIControls/App/app'
import { useSelectControlsApp } from '../Composables/useSelectControlsApp'
import AppComponentPlottingDialog from '@/views/AIControls/App/AppComponentPlottingDialog.vue'
import AppToggleButton from './AppToggleButton.vue'

const appStore = useAppStore()
const { currentProjectControlsAppCount } = storeToRefs(appStore)
const aiControlsAppStore = useAIControlsAppStore()
const { controlsApp, isLoading: isAiConrolsAppStoreLoading } = storeToRefs(aiControlsAppStore)
const route = useRoute()
const router = useRouter()
const { t } = useI18n()
const isExpanded = ref(false)

const appId = computed<string>(() => route.params.appId as string)

onBeforeRouteUpdate(() => {
  isExpanded.value = false
})
// #region CONTROL APPS SELECTOR
const { debouncedSearchApps, fetchMoreControlsApps, selectControlsApp, apps, search } = useSelectControlsApp()
// #endregion

const isLoading = computed(() => currentProjectControlsAppCount.value === null || isAiConrolsAppStoreLoading.value)

const algorithmCard = ref<InstanceType<typeof ControlsAlgorithmCard> | null>(null)
const inputPinCardColumn = ref<InstanceType<typeof PinCardColumn> | null>(null)
const outputPinCardColumn = ref<InstanceType<typeof PinCardColumn> | null>(null)

const yOffset = 30

function removeAllLeaderLines () {
  inputPinCardColumn.value?.cards.forEach((card) => {
    removeLeaderLine(card)
  })

  outputPinCardColumn.value?.cards.forEach((card) => {
    removeLeaderLine(card)
  })
}

watch (() => inputPinCardColumn.value?.cards, (a, b) => {
  const inserted = a?.filter((card) => !b?.includes(card))
  const removed = b?.filter((card) => !a?.includes(card))
  inserted?.forEach((card) => {
    addLeaderLine(
      card,
      LeaderLine.pointAnchor(algorithmCard.value?.$el, { x: 0, y: yOffset }),
      card,
      getStatusCodeMeaning('active', controlsApp.value?.status.code ?? -1),
    )
  })
  removed?.forEach((card) => removeLeaderLine(card))
})

watch (() => outputPinCardColumn.value?.cards, (a, b) => {
  const inserted = a?.filter((card) => !b?.includes(card))
  const removed = b?.filter((card) => !a?.includes(card))
  inserted?.forEach((card) => {
    addLeaderLine(
      LeaderLine.pointAnchor(algorithmCard.value?.$el, { x: '100%', y: yOffset }),
      card,
      card,
      getStatusCodeMeaning('active', controlsApp.value?.status.code ?? -1),
    )
  })
  removed?.forEach((card) => removeLeaderLine(card))
})

onBeforeUnmount(() => {
  removeAllLeaderLines()
  aiControlsAppStore.stopAppPolling()
})

const navigateToLog = () => {
  router.push({
    name: 'ai-controls-log',
    params: { project: appStore.projectId },
    query: {
      id: appId.value,
    },
  })
}

const showComponentPlotting = ref(false)

function toggleComponentPlotting () {
  showComponentPlotting.value = !showComponentPlotting.value
  if (showComponentPlotting.value && controlsApp.value?.component_in_project?.id) {
    vuexStore.dispatch('optimization/selectComponentInProject', controlsApp.value?.component_in_project?.id)
  }
}

const algorithm = computed(() => controlsApp.value?.algorithm.toLowerCase())
const shortcode = computed(() => getAlgorithmShortcode(algorithm.value || ''))
const gradientNr = getRandomGradientNumber()
const isActive = computed(() => getStatusCodeMeaning('active', controlsApp?.value?.status.code ?? -1))

function getAlgorithmInfoOfApp (key: 'name' | 'description') {
  if (controlsApp.value?.algorithm === 'custom') {
    return controlsApp.value[key]
  }

  return t(`ai_controls.algorithm.${algorithm.value}.${key}`)
}

const getToggleIcon = () => {
  return isExpanded.value ? 'fa:far fa-chevron-up' : 'fa:far fa-chevron-down'
}

const toggleDescription = () => {
  isExpanded.value = !isExpanded.value
}

const name = computed(() => getAlgorithmInfoOfApp('name'))
const description = computed(() => getAlgorithmInfoOfApp('description'))
</script>

<template>
  <div class="layout-wrapper text-neutral-darken1 tw-text-sm">
    <PageHeader title-key="links.meta.title.ai_controls_app">
      <ControlsAppSelector
        v-model:search="search"
        class="tw-ml-auto"
        :selected-controls-app="route.params.appId as string ?? null"
        :show-apps-state="false"
        :select-first-item="false"
        :control-apps="apps"
        :project-id="appStore.projectId"
        :fetch-more-controls-apps="fetchMoreControlsApps"
        @search-controls-apps="debouncedSearchApps"
        @select-controls-app="selectControlsApp"
      >
        <template #item-prepend="{ item }">
          <v-icon
            start
            size="small"
            :color="item.raw.custom?.icon.color"
          >
            {{ item.raw.custom?.icon.name }}
          </v-icon>
        </template>
        <template #item-action="{ item }">
          <v-btn
            variant="text"
            class="tw-ml-auto tw-p-0 tw-uppercase text-neutral-darken1"
          >
            <span class="tw-mr-4">{{ item.raw.custom?.algorithm }}</span>
            <v-icon color="neutral-darken4">
              fa:fal fa-arrow-right-long
            </v-icon>
          </v-btn>
        </template>
      </ControlsAppSelector>
    </PageHeader>
    <div class="graph-container">
      <span class="column-title-input tw-mb-4">Input</span>
      <PinCardColumn
        ref="inputPinCardColumn"
        class="pin-card-list-input"
        collection="input"
        :controls-app="isLoading ? undefined : controlsApp"
      />
      <span class="column-title-app tw-mb-4">App</span>
      <ControlsAlgorithmCard
        ref="algorithmCard"
        class="app-container"
        :algorithm="isLoading ? undefined : algorithm"
        :description="description"
        :gradient-nr
        :is-active
        :title="controlsApp?.algorithm === 'custom' ? controlsApp?.name : ''"
        is-big-title
        :is-description-expanded="isExpanded"
        :name="name"
      >
        <template #title-indicator>
          <AppToggleButton />
        </template>
        <template #actions>
          <v-card-actions class="py-0">
            <v-icon
              class="tw-flex-1 text-neutral-darken3 description-toggle-icon"
              size="small"
              role="button"
              @click="toggleDescription"
            >
              {{ getToggleIcon() }}
            </v-icon>
          </v-card-actions>
          <v-card-actions class="pb-2 pt-0">
            <v-btn
              class="tw-flex-1 btn-border tw-bg-white"
              color="primary-darken2"
              height="40px"
              variant="outlined"
              @click="navigateToLog"
            >
              {{ t('log') }}
              <v-icon
                color="primary-darken2"
                size="small"
                end
              >
                fa:far fa-list
              </v-icon>
            </v-btn>
            <v-btn
              class="tw-flex-1 btn-border tw-bg-white"
              color="primary-darken2"
              height="40px"
              variant="outlined"
              @click="toggleComponentPlotting"
            >
              {{ t('datapoints') }}
              <v-icon
                color="primary-darken2"
                size="small"
                end
              >
                fa:far fa-line-chart
              </v-icon>
            </v-btn>
            <AppComponentPlottingDialog
              :is-open="showComponentPlotting"
              :shortcode="shortcode"
              :gradient-nr="gradientNr"
              :is-active="isActive"
              :algorithm-name="t(`ai_controls.algorithm.${controlsApp?.algorithm}.name`)"
              @close="toggleComponentPlotting"
            />
          </v-card-actions>
        </template>
      </ControlsAlgorithmCard>
      <span class="column-title-output tw-mb-4">Output</span>
      <PinCardColumn
        ref="outputPinCardColumn"
        class="pin-card-list-output"
        collection="output"
        :controls-app="isLoading ? undefined : controlsApp"
      />
    </div>
  </div>
</template>

<style lang="sass" scoped>
.layout-wrapper
  font-feature-settings: 'cv01' on, 'ss01' on, 'ss04' on

.graph-container
  display: grid
  width: 100%
  max-width: 1640px

  margin: auto

  grid-template-columns: minmax(250px, 6fr) minmax(40px, 1fr) minmax(min-content, 520px) minmax(40px, 1fr) minmax(250px, 6fr)
  grid-template-areas: "title-input . title-app . title-output" "pin-list-input . app . pin-list-output"

.column-title-input
  grid-area: title-input
.column-title-app
  grid-area: title-app
.column-title-output
  grid-area: title-output
.app-container
  grid-area: app
  min-width: 240px
.pin-card-list-input
  grid-area: pin-list-input
.pin-card-list-output
  grid-area: pin-list-output

.btn-border
  border: 1px solid rgb(var(--v-theme-primary-lighten2))
</style>

<i18n lang="json" locale="de">
  {
    "log": "Log",
    "datapoints": "Datenpunkte"
  }
</i18n>
<i18n lang="json" locale="en">
  {
    "log": "Log",
    "datapoints": "Datapoints"
  }
</i18n>
